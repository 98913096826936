var lazyLoadInstance = new LazyLoad({
    // Your custom settings go here
});

window.lazyLoadInstance = lazyLoadInstance

import "./partials/Header";
import "./partials/BackToTop";
import "./partials/Slider";

jQuery(function () {
});
