const sharedBannerOptions = {
	speed: 1700,
	loop: true,
	autoplay: {
		delay: 4000,
	},
};

const bannerHomeSlider = new Swiper(".home-banner-slider .swiper-container", {
	pagination: {
		el: ".home-banner-slider  .swiper-pagination",
		clickable: true,
	},
	...sharedBannerOptions,
});

const newsSliderInit = () => {
	$(".news-slider").each(function (index) {
		var $this = $(this);
		if ($(this).data("slides") !== undefined) {
			var initSlide = $(this).data("slides");
		} else {
			var initSlide = 4;
		}
		$this.addClass("news-slider-" + index);
		$this.find(".swiper-prev").addClass("news-slider-prev-" + index);
		$this.find(".swiper-next").addClass("news-slider-next-" + index);

		var newsSlider = new Swiper(
			".news-slider-" + index + " .swiper-container",
			{
				slidesPerView: initSlide,
				spaceBetween: 32,
				navigation: {
					prevEl: ".news-slider-prev-" + index,
					nextEl: ".news-slider-next-" + index,
				},
				breakpoints: {
					1024: {
						slidesPerView: 3.5,
						spaceBetween: 16,
						freeMode: true,
					},
					768: {
						slidesPerView: 2.5,
						freeMode: true,
						spaceBetween: 16,
					},
					576: {
						slidesPerView: 1.5,
						spaceBetween: 16,
						freeMode: true,
					},
				},
			}
		);
	});
};

newsSliderInit();

const projectSliderInit = () => {
	$(".project-slider").each(function (index) {
		var $this = $(this);
		if ($(this).data("slides") !== undefined) {
			var initSlide = $(this).data("slides");
		} else {
			var initSlide = 3;
		}
		$this.addClass("project-slider-" + index);
		$this.find(".swiper-prev").addClass("project-slider-prev-" + index);
		$this.find(".swiper-next").addClass("project-slider-next-" + index);

		var projectSlider = new Swiper(
			".project-slider-" + index + " .swiper-container",
			{
				slidesPerView: initSlide,
				spaceBetween: 32,
				navigation: {
					prevEl: ".project-slider-prev-" + index,
					nextEl: ".project-slider-next-" + index,
				},
				breakpoints: {
					1024: {
						slidesPerView: 3.5,
						spaceBetween: 16,
						freeMode: true,
					},
					768: {
						slidesPerView: 2.5,
						freeMode: true,
						spaceBetween: 16,
					},
					576: {
						slidesPerView: 1.5,
						spaceBetween: 16,
						freeMode: true,
					},
				},
			}
		);
	});
};

projectSliderInit();

const projectComingSoonSlider = new Swiper(
	".project-coming-soon-slider .swiper-container",
	{
		navigation: {
			prevEl: ".project-coming-soon-slider .swiper-prev",
			nextEl: ".project-coming-soon-slider .swiper-next",
		},
		spaceBetween: 20,
	}
);

const brandSlider = new Swiper(".brand-slider .swiper-container", {
	slidesPerView: 5,
	spaceBetween: 32,
	navigation: {
		nextEl: ".brand-slider .swiper-next",
		prevEl: ".brand-slider .swiper-prev",
	},
	breakpoints: {
		1024: {
			slidesPerView: 3.5,
			spaceBetween: 24,
		},
		768: {
			slidesPerView: 3.5,
			spaceBetween: 24,
		},
		576: {
			slidesPerView: 2.5,
			spaceBetween: 24,
		},
	},
	autoplay: {
		delay: 4000,
	},
	loop: true,
});

const yearContentSlider = new Swiper(
	".history-content-slider .swiper-container",
	{
		slidesPerView: 3,
		spaceBetween: 32,
		observer: true,
		observeParents: true,
		navigation: {
			prevEl: ".history-content-slider .swiper-prev",
			nextEl: ".history-content-slider .swiper-next",
		},
		breakpoints: {
			1440: {
				spaceBetween: 24,
			},
			1024: {
				slidesPerView: 2.5,
				spaceBetween: 16,
			},
			768: {
				slidesPerView: 1.5,
				spaceBetween: 16,
			},
			576: {
				slidesPerView: 1.25,
				spaceBetween: 16,
			},
		},
	}
);

const homeGallerySlider = () => {
	$(".gallery-image-slider-wrap").each(function () {
		let galleryThumbContainer = $(this).find(
			".gallery-slider-thumbnail .swiper-container"
		);
		let galleryImageContainer = $(this).find(
			".gallery-slider-images .swiper-container"
		);
		let btnPrev = $(this).find(
			".gallery-slider-images .swiper-prev"
		);
		let btnNext = $(this).find(
			".gallery-slider-images .swiper-next"
		);
		const galleryThumbnailSlider = new Swiper(
			galleryThumbContainer,
			{
				spaceBetween: 8,
				slidesPerView: 5,
				observer: true,
				observeParents: true,
				slideToClickedSlide: true,
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
				breakpoints: {
					1024: {
						slidesPerView: 4.5,
						spaceBetween: 8,
					},
					768: {
						slidesPerView: 3.5,
						spaceBetween: 8,
					},
					576: {
						slidesPerView: 2.5,
						spaceBetween: 8,
					},
				},
			}
		);
		const galleryImageSlider = new Swiper(
			galleryImageContainer,
			{
				effect: "fade",
				fadeEffect: { crossFade: true },
				observer: true,
				observeParents: true,
				thumbs: {
					swiper: galleryThumbnailSlider,
				},
				navigation: {
					prevEl: btnPrev,
					nextEl: btnNext,
				}
			}
		);
	});
};

homeGallerySlider();

$(".home-gallery-tab").tabslet({
	container: "#homeGalleryTab",
});